import React from 'react'
import { Link } from 'gatsby'
import Insta from './icons/insta'
import Twitter from './icons/twitter'
import s from './footer.module.css'

export default function Footer(props) {
  const { theme } = props
  return (
    <div id="footer" className={`theme--${theme || 'light'} u-container-x ${s.root}`}>
      <footer className={`${s.container} u-container-content `}>
        <div className={s.socialNav}>
          <h4>Visit us on Social</h4>
          <a href="https://www.instagram.com/defund2refund/" target="_blank" aria-label="Follow us on Instagram">
            <Insta alt="Follow us on Instagram" />
          </a>
          <a href="https://twitter.com/Defund2Refund" target="_blank" aria-label="Follow us on Twitter">
            <Twitter alt="Follow us on Twitter" />
          </a>
        </div>
        <nav className={s.nav}>
          <div className={s.links}>
            <Link to="/page/contact">Contact</Link>
            <Link to="/page/privacy-policy">Terms</Link>
          </div>
        </nav>
      </footer>
    </div>
  )
}
