import React, { useState } from "react";
import Img from "gatsby-image";

import VisibilitySensor from "react-visibility-sensor";

import { getFluidGatsbyImage } from "../getFluidGatsbyImage";
import s from "./art.module.css";

export default function Art(props) {
  const { caption, alt, image, theme } = props;

  const [visible, setVisible] = useState(false);
  const onChange = (isVisible) => {
    setVisible(isVisible);
  };

  const src = image["en-US"].fields.file["en-US"];
  const fluid = getFluidGatsbyImage({ file: src }, {});

  const styles = {};
  const textColor = theme && theme["en-US"];
  if (textColor) styles["--color-art-text"] = `var(--color-${textColor})`;

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
      <div className={s.outer}>
        <div className={`${s.art} ${visible ? s.visible : s.hidden}`}>
          <div className="u-container-story-content" style={styles}>
            {caption && (
              <h3>
                <span>{caption["en-US"]}</span>
              </h3>
            )}
          </div>

          <Img className={s.artInner} fluid={fluid} alt={alt["en-US"]} />
        </div>
      </div>
    </VisibilitySensor>
  );
}
