import React, { useEffect } from 'react'
import VisibilitySensor from "react-visibility-sensor"
import s from './datapoint.module.css'
import RichText from './richtext'
import { stripEn } from '../utils'

export default function DataPoint(props) {
  const {
    id,
    label,
    labelShort,
    cost,
    costBrev,
    richText,
    updateDefunds,
  } = stripEn(props)

  let detail = null;

  useEffect(() => {
    detail = document.getElementById(id)
    detail.addEventListener("toggle", event => {
      if (detail.open) {
        handleClick('expand')
      } else {
        handleClick('collapse')
      }
    });
  }, [])

  // Analytics when clicked
  const handleClick = (direction) => {
    const datalayer = typeof window !== 'undefined' ? window.datalayer : {}
    datalayer.push({
      'event': 'trackEvent',
      'category': 'story actions',
      'action': `cost ${direction}`,
      'label':  label,
    })
  }
  
  // When the Datapoint becomes visible
  const onChange = (isVisible) => {
    if (isVisible) {
      updateDefunds({ label, cost, id })
    }
  }

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <details id={id} disabled className={`${s.root} ${props.outcome ? s.outcome : ''}`}>
        <summary>
          <div className={s.summary} >
            <span className={s.label}>{labelShort || label}</span>
            <span className={s.cost}>${costBrev || cost.toLocaleString()}</span>
            <div className={s.icon} />
          </div>
        </summary>
        <div className={s.description}>
          <RichText json={richText} />
        </div>
      </details>
    </VisibilitySensor>
  )
}
