import React from "react";
import get from "lodash/get";
import { graphql, useStaticQuery } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import ScrollContainer from "react-indiana-drag-scroll";
import Img from "gatsby-image";
import s from "./story-cards.module.css";

export default function StoryCards() {
  const data = useStaticQuery(storiesQuery);
  const stories = get(data, "allContentfulStory.edges", []);

  const styles = {
    width: `calc((var(--card-width) + var(--card-space-between)) * ${stories.length})`,
  };

  return (
    <ScrollContainer
      vertical={false}
      hideScrollbars={true}
      className={s.scroll}
    >
      <div className={`${s.root} `} style={styles}>
        {stories.map(({ node }) => {
          const { slug, headline, hero, category } = node;
          return (
            <AniLink
              cover
              bg="#1D1A18"
              key={slug}
              className={s.story}
              to={`/stories/${slug}`}
            >
              <div className={s.info}>
                <span className={s.category}>{category}</span>
                <h3 className={s.headline}>{headline}</h3>
              </div>
              {hero && (
                <Img
                  className={s.image}
                  alt={hero.alt}
                  fluid={hero.image.fluid}
                />
              )}
              {hero.artist && (
                <div className={s.author}>
                  <h4>Illustrations By</h4>
                  <p>{hero.artist.name}</p>
                </div>
              )}
            </AniLink>
          );
        })}
      </div>
    </ScrollContainer>
  );
}

export const storiesQuery = graphql`
  query StoriesQuery {
    allContentfulStory(limit: 10) {
      edges {
        node {
          category
          headline
          slug
          hero {
            image {
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`;
