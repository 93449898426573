import React from 'react'
import VisibilitySensor from "react-visibility-sensor"
import s from './outcome.module.css'
import { stripEn } from '../utils'

export default function Outcome(props) {
  const { label, dataPoints, totalAbbreviated, updateDefunds } = stripEn(props)
  let total = 0

  const onChange = (isVisible) => {
    if (isVisible) {
      dataPoints.forEach(point => {
        const id = point.sys.contentful_id
        const fields = point.fields
        const { label, cost } = stripEn(fields)
        updateDefunds({ label, cost, id })
      });
    }
  }


  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
    
    <div className={`${s.root}`}>
      {dataPoints.map(point => {
        const fields = point.fields
        const { label, labelShort, cost, costBrev } = stripEn(fields)
        total = total + cost
        return (
          <div key={label} className={s.point}>
            <h3 className={s.label}>{labelShort || label}</h3>
            <h3 className={s.cost}>${costBrev || (cost / 1000).toLocaleString() + 'K'}</h3>
          </div>
        )
      })}
      <hr />
      <div className={s.point}>
        <h3 className={s.label}>{label}</h3>
        <h3 className={s.cost}>${totalAbbreviated || `${(total / 1000).toLocaleString()}K` }</h3>
      </div>
      </div>
    </VisibilitySensor>
  )
}
