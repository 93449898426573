import React, { useState } from "react";
import ReactModal from "react-modal";
import { graphql, Link, useStaticQuery } from "gatsby";
import s from "./navigation.module.css";
import Insta from "./icons/insta";
import Twitter from "./icons/twitter";
import { get } from "lodash";
import Img from "gatsby-image";

const determineRoute = function (type, slug, rootLink) {
  let url = `/page/${slug}`;
  if (type === "story") url = `/stories/${slug}`;
  if (rootLink) url = `/${slug}`;
  if (slug === "home") url = "/";
  return url;
};

const modalStyle = {
  overlay: {
    backgroundColor: "var(--color-black-warm)",
    zIndex: 100,
    display: "flex",
    alignItems: "center",
  },
  content: {
    width: "240px",
    top: 0,
    paddingTop: 0,
    left: "unset",
    bottom: "unset",
    right: "unset",
    position: "relative",
    margin: "0 auto",
    textAlign: "center",
    transform: "none",
    backgroundColor: "transparent",
    border: "none",
  },
};

export default function Navigation(props) {
  const data = useStaticQuery(navQuery);
  const [open, setOpen] = useState(false);

  const links = get(data, "contentfulGlobal.nav", []);

  return (
    <nav
      role="navigation"
      className={`u-container-content u-container-x ${s.navigation}`}
    >
      <button
        aria-label="open main navigation"
        className={`${open ? s.isActive : ""} ${s.hamburger}`}
        onClick={() => setOpen(!open)}
      >
        <span />
      </button>
      <ReactModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        closeTimeoutMS={500}
        style={modalStyle}
        ariaHideApp={false}
      >
        <button
          aria-label="close main navigation"
          className={`${s.isActive} ${s.hamburger} ${s.hamburgerClose}`}
          onClick={() => setOpen(false)}
        >
          <span />
        </button>

        {links.map((link) => {
          const type = get(link, "sys.contentType.sys.id");
          const { slug, headline, rootLink, category } = link;
          const url = determineRoute(type, slug, rootLink);

          return (
            <Link
              key={slug}
              to={url}
              className={type === "story" ? s.storyLink : `cta ${s.pageLink}`}
            >
              {category && <span className={s.category}>{category}.</span>}
              {headline && <span className={s.headline}>{headline}</span>}
            </Link>
          );
        })}

        <div className={s.social}>
          <a
            href="https://www.instagram.com/defund2refund/"
            target="_blank"
            rel="noreferrer"
          >
            <Insta alt="Follow us on Instagram" />
          </a>
          <a
            href="https://twitter.com/Defund2Refund"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter alt="Follow us on Twitter" />
          </a>
        </div>
      </ReactModal>
    </nav>
  );
}

export const navQuery = graphql`
  query NavQuery {
    contentfulGlobal {
      nav {
        ... on ContentfulPage {
          id
          slug
          rootLink
          headline
          title
          sys {
            type
            contentType {
              sys {
                type
                linkType
                id
              }
            }
          }
        }
        ... on ContentfulStory {
          id
          category
          headline
          slug
          hero {
            image {
              sizes {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
              }
              file {
                url
              }
            }
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
`;
