import React from "react";
import Art from "./art";
import DataPoint from "./datapoint";
import AssetCollection from "../asset-collection";
import get from "lodash/get";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Error from "../error";
import Outcome from "./outcome";
import Reallocations from "./reallocations";
import Modal from "./modal";

const types = {
  assetsCollection: (props) => (
    <AssetCollection {...props.fields} id={props.id} />
  ),
  art: (props) => <Art {...props.fields} id={props.id} />,
  dataPoint: (props) => (
    <DataPoint
      {...props.fields}
      id={props.id}
      updateDefunds={props.updateDefunds}
    />
  ),
  outcome: (props) => (
    <Outcome
      {...props.fields}
      id={props.id}
      updateDefunds={props.updateDefunds}
    />
  ),
  reallocations: (props) => <Reallocations {...props.fields} id={props.id} />,
  modal: (props) => <Modal {...props.fields} id={props.id} />,
  Asset: (props) => <img src={props.src} alt={props.description} />,
  link: (props) => {
    const label = get(props, "fields.label.en-US");
    const href = get(props, "fields.url.en-US");

    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="cta cta--richtext"
      >
        {label}
      </a>
    );
  },
};

const RenderEmbeddedEntry = (props) => {
  const { type } = props;

  return types[type] ? (
    types[type](props)
  ) : (
    <Error {...props.fields} type={type} />
  );
};

const renderRichText = ({ json, updateDefunds }) => {
  const options = {
    renderText: (text) =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const type = node.data.target.sys.contentType.sys.id;
        const id = node.data.target.sys.contentful_id;

        return (
          <RenderEmbeddedEntry
            {...node.data.target}
            type={type}
            id={id}
            updateDefunds={updateDefunds}
          />
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        // const type = get(node, "data.target.sys.type");
        const src = get(node, "data.target.fields.file.en-US.url");
        const description = get(
          node,
          "data.target.fields.file.en-US.description"
        );
        const id = node.data.target.sys.contentful_id;

        return <img id={id} src={src} alt={description} />;
      },
      [INLINES.HYPERLINK]: (node) => {
        const text = get(node, "content[0].value");
        const url = get(node, "data.uri");
        const newWindow = url.includes("//");

        return (
          <a
            target={newWindow ? "_blank" : "_self"}
            rel={newWindow ? "noreferrer" : null}
            href={url}
          >
            {text}
          </a>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const type = node.data.target.sys.contentType.sys.id;
        const id = node.data.target.sys.contentful_id;

        return (
          <RenderEmbeddedEntry {...node.data.target} type={type} id={id} />
        );
      },
    },
  };

  const content = json ? documentToReactComponents(json, options) : null;

  return <div className="richtext">{content}</div>;
};

export default renderRichText;
