import React from 'react'
import { Helmet } from 'react-helmet'

export default function Meta({
  title,
  description,
  image,
  imageAlt,
  url,
  defaultMeta,
  jsonLD,
}) {
  let newTitle = title;
  console.log('MJ ~ file: meta.js ~ line 14 ~ newTitle', newTitle)
  if (newTitle !== 'Defund to Refund') newTitle = `${title} | Defund to Refund`

  return (
    <Helmet title={newTitle}>
      <meta name="description" content={description || defaultMeta.description} />
      <meta property="og:title" content={newTitle} />
      <meta property="og:description" content={description || defaultMeta.description}/>
      <meta property="og:image" content={image || defaultMeta.image} />
      <meta property="og:url" content={url || defaultMeta.siteUrl} />
      <meta property="og:url" content={url || defaultMeta.siteUrl} />
      <meta property="twitter:domain" content='refund2defund.org' />
      <meta name="twitter:description" content={description || defaultMeta.description} />
      <meta name="twitter:image" content={image || defaultMeta.image} />
      <meta name="twitter:image:alt" content={imageAlt} />
      <meta name="twitter:card" content="summary_large_image" />
      {jsonLD && (
        <script type="application/ld+json">{`
        ${JSON.stringify(jsonLD)}
        `}</script>
      )}
    </Helmet>
  )
}
