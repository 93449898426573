import React from "react";
import RichText from "./richtext";
import s from "./reallocations.module.css";
import { stripEn } from "../utils";

export default function Reallocations(props) {
  const { label, reallocations, totalAbbreviated } = stripEn(props);

  let total = 0;

  return (
    <div className={s.root}>
      {reallocations.map((item) => {
        const { cost, costBrev, descriptionRich, label, subLabel } = stripEn(
          item.fields
        );

        total = total + cost;
        return (
          <div className={s.item} key={item.sys.id}>
            <h3>
              <span className={s.itemLabel}>{label}</span>
              <span>${costBrev || cost.toLocaleString()}</span>
            </h3>
            <h4 className={s.subLabel}>{subLabel}</h4>
            <div className={s.description}>
              <RichText json={descriptionRich} />
            </div>
          </div>
        );
      })}
      <h3 className={s.total}>
        <span className={s.totalLabel}>{label}</span>
        <span className={s.totalAmt}>
          ${totalAbbreviated || total.toLocaleString()}
        </span>
      </h3>
    </div>
  );
}
