import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import RichText from "./story/richtext";
import StoryCards from "./story-cards";
import ArtistCards from "./artist-cards";
import Logo from "../components/icons/logo";
import s from "./section.module.css";

gsap && gsap.registerPlugin(ScrollTrigger, CSSRulePlugin);

export default function Section(props) {
  const {
    heading,
    body,
    theme,
    variant,
    ctaLabel,
    ctaLink,
    dividerAbove,
    dividerBelow,
    dividerStyle,
    justStories,
    photo,
    useH1,
  } = props;

  // ScrollTrigger Logic
  const ref = useRef(null);
  useEffect(() => {
    const $el = ref.current;
    const $heading = $el.querySelector(".heading");
    const $body = $el.querySelector(".body");
    const $lineAbove = $el.querySelector(".dividerAboveDom");
    const $lineBelow = $el.querySelector(".dividerBelowDom");

    // Homepage Pinning of Content
    if (variant === "home hero") {
      ScrollTrigger.create({
        trigger: $el,
        start: "top top",
        pin: true,
        pinSpacing: false,
      });
    }

    gsap.from($heading, {
      scrollTrigger: {
        trigger: $el,
      },
      y: 50,
      duration: 0.75,
      ease: "power1.out",
    });

    gsap.from($body, {
      scrollTrigger: {
        trigger: $el,
      },
      y: 100,
      duration: 0.75,
      ease: "power1.out",
    });

    if ($lineAbove) {
      gsap.from($lineAbove, {
        scrollTrigger: {
          trigger: $el,
          start: "top 90%",
        },
        scaleY: 0,
        duration: 2,
        ease: "power1.inOut",
      });
    }

    if ($lineBelow) {
      gsap.from($lineBelow, {
        scrollTrigger: {
          trigger: $el,
          start: "top center",
        },
        scaleY: 0,
        duration: 2,
        ease: "power1.inOut",
      });
    }
  }, []);

  let variantClass = "";
  if (variant === "home hero") variantClass = s.homeHero;
  if (variant === "stories") variantClass = s.stories;
  if (variant === "artist") variantClass = s.artist;
  const classAbove = dividerAbove ? "dividerAbove" : "";
  const classBelow = dividerBelow ? "dividerBelow" : "";

  let headline = (
    <h2
      className={`${s.heading} heading ${
        variant === "serif headline" && s.headingAlt
      }`}
    >
      {heading}
    </h2>
  );
  if (useH1)
    headline = (
      <h1
        className={`${s.heading} heading ${
          variant === "serif headline" && s.headingAlt
        }`}
      >
        {heading}
      </h1>
    );

  return (
    <section
      ref={ref}
      className={`
        theme--${theme || "light"} 
        ${classAbove} 
        ${classBelow} 
        ${justStories ? s.justStories : ""} 
        ${s.root} 
        u-container-x
        ${variantClass}
    `}
    >
      {dividerAbove && <div className="dividerAboveDom" />}

      <div className={`u-container-content ${s.container}`}>
        {photo ? (
          <div className={s.photo}>
            <Img fluid={photo.fluid} alt={photo.alt} />
          </div>
        ) : null}
        {!photo ? headline : null}

        <div className={`${s.body} body`}>
          {photo ? headline : null}
          <RichText {...body} />
          {ctaLink && (
            <div>
              <Link
                className={s.cta}
                to={`/${!ctaLink.rootLink ? "page/" : ""}${ctaLink.slug}`}
              >
                {ctaLabel}
              </Link>
            </div>
          )}
        </div>
      </div>
      {variant === "stories" && <StoryCards />}
      {variant === "artists" && <ArtistCards />}

      {variant === "home hero" && <Logo className={s.logo} />}

      {dividerBelow && <div className={`dividerBelowDom ${dividerStyle}`} />}
    </section>
  );
}
