import React from 'react'

function IconTwitter(props) {
  return (
    <svg
      width={23}
      height={19}
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.236 18.844c8.677 0 13.424-7.19 13.424-13.425 0-.202-.005-.408-.014-.61A9.584 9.584 0 0023 2.366a9.304 9.304 0 01-2.708.74A4.745 4.745 0 0022.367.498a9.485 9.485 0 01-2.996 1.145 4.725 4.725 0 00-8.044 4.303A13.402 13.402 0 011.603 1.02a4.724 4.724 0 001.46 6.296 4.724 4.724 0 01-2.138-.588v.058a4.717 4.717 0 003.786 4.627 4.69 4.69 0 01-2.128.08 4.727 4.727 0 004.405 3.28A9.464 9.464 0 010 16.724a13.387 13.387 0 007.236 2.12z"
        fill="var(--color-text)"
      />
    </svg>
  )
}

export default IconTwitter
