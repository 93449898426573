import React from 'react'
import get from 'lodash/get'
import { stripEn } from './utils'
import s from './asset-collection.module.css'

export default function AssetCollection(props) {
  const { assets } = stripEn(props)

  return (
    <div>
      {assets.map(item => {
        const src = get(item, 'fields.file.en-US.url')
        const alt = get(item, 'fields.title.en-US')
        return <img className={s.item} src={src} alt={alt} />
      })}
    </div>
  )
}
