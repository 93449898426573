import React from 'react'

function LogoIcon(props) {
  return (
    <svg
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 211.66 243.79"
      {...props}
    >
      <defs>
        <style>{".logo{fill:var(--color-text)}"}</style>
      </defs>
      <title>{"d2r-logo-cream"}</title>
      <path
        className="logo"
        d="M33.15 43.42V15.57C33.15 6.26 26.62 0 17 0H0v58h17c9.62 0 16.15-5.28 16.15-14.58zM13.4 47V11.05H16c2.61 0 3.74 1.48 3.74 4.35v27.14C19.75 45.42 18.62 47 16 47zM66.16 46.11H51.19V33.23h9.75v-10h-9.75V11.22h14.97V0H37.79v57.95h28.37V46.11zM83.77 34.1h9.75V23.4h-9.75V10.7h14.1V0h-27.5v57.95h13.4V34.1zM118.06 59.25c9.48 0 16-6.35 16-14.36V0h-12.87v44.89a2.86 2.86 0 01-3.13 2.88 2.81 2.81 0 01-3-2.88V0h-13.45v44.89c0 8.01 7.14 14.36 16.45 14.36zM150.89 28.45l3.31 8.18 8.18 21.32h11.05V0h-11.75v29.84l-3.13-8.26L150.89 0h-11.83v57.95h11.83v-29.5zM195.48 0h-17v58h17c9.66 0 16.18-5.23 16.18-14.53v-27.9c0-9.31-6.52-15.57-16.18-15.57zm2.78 42.54c0 2.88-1.13 4.44-3.74 4.44h-2.61V11.05h2.61c2.61 0 3.74 1.48 3.74 4.35zM165.13 134.94V88.48h9.49V77h-32.28v11.48h9.39v46.46h13.4zM195 136.25c9.39 0 16.7-6.09 16.7-15.14V90.83c0-9-7.31-15-16.7-15s-16.8 6.09-16.8 15v30.28c-.04 9.05 7.27 15.14 16.8 15.14zm-3.14-46.2a2.9 2.9 0 013.14-2.87 2.83 2.83 0 013 2.87v31.84a2.83 2.83 0 01-3 2.88 2.91 2.91 0 01-3.14-2.88zM31.93 222.21c0-3-2.17-6.09-5.65-7.74a8.86 8.86 0 005.39-7.66v-10.09c0-7.83-6.35-12.18-12.88-12.18H0v57.94h13.4V219.6h3c1.92 0 2.88 1.83 2.88 3.31v10.61c0 3.92 1 9 3.13 9h11.17v-.26a30.17 30.17 0 01-1.65-9.39zm-13-15c0 2-.79 2.78-2.26 2.78H13.4v-15h3.31c1.47 0 2.26.78 2.26 2.78zM37.79 242.48h28.37v-11.83H51.19v-12.88h9.75v-10h-9.75v-12.01h14.97v-11.22H37.79v57.94zM70.37 242.48h13.4v-23.83h9.75v-10.71h-9.75v-12.7h14.1v-10.7h-27.5v57.94zM121.19 229.43a2.85 2.85 0 01-3.13 2.87 2.8 2.8 0 01-3-2.87v-44.89h-13.45v44.89c0 8 7.14 14.36 16.45 14.36 9.48 0 16-6.35 16-14.36v-44.89h-12.87zM161.68 214.38l-3.13-8.26-7.66-21.58h-11.83v57.94h11.83v-29.49l3.31 8.18 8.18 21.31h11.05v-57.94h-11.75v29.84zM195.48 184.54h-17v57.94h17c9.66 0 16.18-5.22 16.18-14.53v-27.84c0-9.31-6.52-15.57-16.18-15.57zm2.78 42.54c0 2.88-1.13 4.44-3.74 4.44h-2.61v-35.93h2.61c2.61 0 3.74 1.48 3.74 4.35zM0 154h211.66v11.48H0z"
      />
    </svg>
  )
}

export default LogoIcon