import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { stripEn } from '../utils'
import RichText from './richtext'
import s from './modal.module.css'

const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(29, 26, 24, 0.5)'
  },
  content: {
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#F7F3EB',
    color: '#000',
    width: '325px',
    left: 'auto',
    borderRadius: 0,
    padding: '35px 30px',
    border: 'none',
  }
}

export default function ModalTrigger(props) {
  const [open, setOpen] = useState(false);
  const datalayer = typeof window !== 'undefined' ? window.datalayer : {}

  const {
    body,
    label,
    headline,
  } = stripEn(props)

  const handleOpen = () => {
    setOpen(true)
    datalayer.push({
      'event': 'trackEvent',
      'category': 'story actions',
      'action': 'data open',
      'label':  label
    })
  }
  const handleClose = () => {
    setOpen(false)
    datalayer.push({
      'event': 'trackEvent',
      'category': 'story actions',
      'action': 'data close',
      'label':  label
    })
  }


  return (
    <span>
      <span
        role="button"
        tabIndex="0"
        onClick={handleOpen}
        className={s.trigger}
        label={label}
      >{label}</span>
      <ReactModal
        isOpen={open}
        onRequestClose={handleClose}
        closeTimeoutMS={500}
        style={modalStyle}
        ariaHideApp={false}
      >
        <button label="close" className={s.close} onClick={handleClose} />
        <h4 className={s.headline}>{headline}</h4>
        <RichText json={body} /> 
      </ReactModal>
    </span>
  )
}


