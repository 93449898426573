import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import base from "./base.css";
import SimpleReactLightbox from "simple-react-lightbox";
import Container from "./container";
import Navigation from "./navigation";
import Logo from "./icons/logo";
import Footer from "./footer";

class Template extends React.Component {
  render() {
    const { location, children, theme, className } = this.props;

    const isHome = location.pathname === "/";

    return (
      <SimpleReactLightbox>
        <Container className={className}>
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cloud.typography.com/6526698/6486612/css/fonts.css"
            />
            <link
              rel="apple-touch-icon"
              sizes="57x57"
              href="/apple-icon-57x57.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="60x60"
              href="/apple-icon-60x60.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="72x72"
              href="/apple-icon-72x72.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="76x76"
              href="/apple-icon-76x76.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="114x114"
              href="/apple-icon-114x114.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="120x120"
              href="/apple-icon-120x120.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="144x144"
              href="/apple-icon-144x144.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/apple-icon-152x152.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-icon-180x180.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href="/android-icon-192x192.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="96x96"
              href="/favicon-96x96.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
          </Helmet>

          <Navigation />

          {!isHome && (
            <div
              className={`u-container-x site-logo-wrap ${
                isHome && "site-logo--home"
              }`}
            >
              <h1 className={`site-logo u-container-content`}>
                <Link to="/">
                  <Logo />
                </Link>
              </h1>
            </div>
          )}

          <div>{children}</div>

          <Footer theme={theme} />
        </Container>
      </SimpleReactLightbox>
    );
  }
}

export default Template;
