import React from 'react'
import get from 'lodash/get'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import s from './artist-cards.module.css'

export default function ArtistCards() {
  const data = useStaticQuery(artistsQuery)
  const artists = get(data, 'allContentfulArtist.edges')

  return (
    <div className={`u-container-content ${s.root}`}>
      {artists.map(({ node }) => {
        const { slug, name, photo, id } = node
        return (
          <Link key={id} className={s.artist} to={`/artist/${slug}`}>
          {photo &&
            <Img className={s.image} alt={`Photograph of ${name}`} fluid={{...photo.fluid, aspectRatio: 374 / 505}} />
          }
            <h3 className={s.headline}>{name}</h3>
          </Link>
        )
      })} 
    </div>
  )
}

export const artistsQuery = graphql`
  query ArtistsQuery {
    allContentfulArtist {
      edges {
        node {
          name
          slug
          id
          photo {
            fluid(maxWidth: 560) {
              ...GatsbyContentfulFluid
            }
            id
          }
        }
      }
    }
  }
`
